import * as jose from 'jose';

import { TransactionCode } from './types';
import { parseIban } from './girotable';

const isObject = (obj: any) => obj && typeof obj === 'object';

export const noop = (..._params: any[]) => {
  // Do nothing
};
export const escapeJSON = (string: string) => {
  return string.replaceAll(/[\b\t\n\f\r"&]/g, String.raw`\$&`);
};

export const escapeRegExp = (value: string) => {
  return value.replaceAll('\n', String.raw`\n`); // $& means the whole matched string
};

export const mergeDeep = (...objects: any) => {
  // eslint-disable-next-line unicorn/no-array-reduce
  return objects.reduce((prev: any, obj: any) => {
    if (obj) {
      for (const key of Object.keys(obj)) {
        const pVal = prev[key];
        const oVal = obj[key];

        if (Array.isArray(pVal) && Array.isArray(oVal)) {
          prev[key] = [...pVal, ...oVal];
        } else if (isObject(pVal) && isObject(oVal)) {
          prev[key] = mergeDeep(pVal, oVal);
        } else {
          prev[key] = oVal;
        }
      }
    }

    return prev;
  }, {});
};

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    noop(error);
    return false;
  }
};

export const cryptoKeyPairGenerate = async () => {
  const { publicKey, privateKey } = await jose.generateKeyPair('RS256', {
    extractable: true
  });
  const pubKey = await jose.exportSPKI(publicKey);
  const privKey = await jose.exportPKCS8(privateKey);
  const jwk = await jose.exportJWK(publicKey);
  const thumbprint = await jose.calculateJwkThumbprint(jwk);
  return { pubKey, privKey, thumbprint, jwk };
};

export const signData = async (pid: string, privateKey: string, data: any) => {
  const key = await jose.importPKCS8(privateKey, 'RS256');
  const thumb = await getThumbprint(pid);
  const jwt = await new jose.SignJWT(data).setProtectedHeader({ alg: 'RS256', kid: thumb! }).sign(key);
  return jwt;
};

export const generateCryptoKeys = async (pid: string) => {
  const { pubKey, privKey, thumbprint } = await cryptoKeyPairGenerate();
  localStorage.setItem(`prv_${pid}`, privKey);
  localStorage.setItem(`pub_${pid}`, pubKey);
  localStorage.setItem(`thumbprint_${pid}`, thumbprint);
  return { pubKey, privKey, thumbprint };
};

export const getPrivKeyCookie = (pid: string) => {
  return localStorage.getItem(`prv_${pid}`);
};

export const getJWK = async (pid: string) => {
  const token = getPubKeyCookie(pid);
  if (token === null) {
    return null;
  }
  const pubkey = await jose.importSPKI(token, 'RS256', { extractable: true });
  const jwk = await jose.exportJWK(pubkey);
  return jwk;
};

export const getPubKeyCookie = (pid: string) => {
  const token = localStorage.getItem(`pub_${pid}`);
  return token;
};

export const getThumbprint = (pid: string) => {
  return localStorage.getItem(`thumbprint_${pid}`);
};

// Function to extract clearing number and account number from IBAN
function _extractClearingAndAccountNumber(iban: string) {
  // Remove spaces from the IBAN
  iban = iban.replaceAll(/\s+/g, '');

  // Get the substring starting from the 6th character
  const substring = iban.slice(5);

  // Find the first digit after a bunch of zeroes
  const match = substring.match(/0+(\d+)/);

  // Extract the clearing code
  const clearingCode = match && match[1].length >= 4 ? match[1].slice(0, 4) : '0000';

  // Extract the account number (everything after the clearing code)
  const accountNumber = clearingCode && match ? substring.slice(substring.indexOf(clearingCode) + 4) : '0';

  return [clearingCode, accountNumber];
}
// Function to create the LB file header
function createLbHeader(
  senderBankgiro: string,
  creationDate: string,
  productName: string,
  paymentDate: string,
  currencyCode: string
): string {
  const transactionCode = TransactionCode.HEADER;
  const formattedSenderBankgiro = senderBankgiro.padStart(10, '0');
  const formattedCreationDate = creationDate.padEnd(6, ' ');
  const formattedProductName = productName.toUpperCase().padEnd(22, ' ');
  const formattedPaymentDate = paymentDate.padEnd(6, ' ');
  const reservedField1 = ' '.repeat(13);
  const formattedCurrencyCode = currencyCode.padEnd(3, ' ');
  const reservedField2 = ' '.repeat(18);

  return (
    transactionCode +
    formattedSenderBankgiro +
    formattedCreationDate +
    formattedProductName +
    formattedPaymentDate +
    reservedField1 +
    formattedCurrencyCode +
    reservedField2
  );
}

// Function to create an Account Information Record
function createAccountInfoRecord(
  transferNumber: string,
  clearingNumber: string,
  accountNumber: string,
  internalReference: string
): string {
  const transactionCode = TransactionCode.ACCOUNT_INFO;
  const reservedField1 = '0000';
  const formattedTransferNumber = transferNumber.padStart(6, '0');
  const formattedClearingNumber = clearingNumber.padStart(4, '0');
  const formattedAccountNumber = accountNumber.padStart(12, '0');
  const formattedInternalReference = internalReference.padEnd(12, ' ');
  const salary = ' ';
  const reservedField = ' '.repeat(39);
  console.log(formattedAccountNumber);
  return (
    transactionCode +
    reservedField1 +
    formattedTransferNumber +
    formattedClearingNumber +
    formattedAccountNumber +
    formattedInternalReference +
    salary +
    reservedField
  );
}

// Function to create a Payment Record (TK14)
function createLbRecord(
  accountNumber: string,
  ocrReferens: string,
  amount: number,
  paymentDate: string,
  infoToSender: string
): string {
  const transactionCode = TransactionCode.PAYMENT;
  const formattedAccountNumber = accountNumber.padStart(10, '0');
  const formattedOcrReferens = ocrReferens.padEnd(25, ' ');
  const formattedAmount = Math.round(amount * 100)
    .toString()
    .padStart(12, '0');
  const formattedPaymentDate = paymentDate.padEnd(6, ' ');
  const reservedSpace = ' '.repeat(5); // Reserved field is blank, 5 characters

  return (
    transactionCode +
    formattedAccountNumber +
    formattedOcrReferens +
    formattedAmount +
    formattedPaymentDate +
    reservedSpace +
    infoToSender.padEnd(20, ' ')
  );
}

// Function to create the LB file footer
function createLbFooter(senderBankgiro: string, totalRecords: number, totalAmount: number): string {
  const transactionCode = TransactionCode.FOOTER;
  const formattedSenderBankgiro = senderBankgiro.padStart(10, '0');
  const formattedTotalRecords = totalRecords.toString().padStart(8, '0');
  const formattedTotalAmount = Math.round(totalAmount * 100)
    .toString()
    .padStart(12, '0');
  const reservedField = ' '.repeat(49);
  return transactionCode + formattedSenderBankgiro + formattedTotalRecords + formattedTotalAmount + reservedField;
}
// Function to generate the full LB file content as a string
export const generateLbFileContent = (
  senderIban: string,
  creationDate: string,
  productName: string,
  paymentDate: string,
  currencyCode: string,
  records: Array<{
    iban: string;
    internalReference: string;
    ocrReferens: string;
    amount: number;
    paymentDate: string;
    infoToSender: string;
  }>
) => {
  // Extract clearing number and account number from sender's IBAN (merchant's IBAN)
  const [_senderClearingNumber, _senderAccountNumber] = parseIban(senderIban);
  const files = [];
  const lbHeader = createLbHeader('0123456789', creationDate, productName, paymentDate, currencyCode);
  let fileCount = 0;
  let recordCounter = 1;
  let totalAmountFile = 0;
  let recordList = '';
  for (const value of records) {
    const [clearingNumber, accountNumber] = parseIban(value.iban);
    const transferNumber = (recordCounter * 10).toString();
    const accountInfoRecord = createAccountInfoRecord(
      transferNumber,
      clearingNumber,
      accountNumber,
      value.internalReference
    );
    const paymentRecord = createLbRecord(
      transferNumber,
      value.ocrReferens,
      value.amount,
      value.paymentDate,
      value.infoToSender
    );
    recordList = recordList + accountInfoRecord + '\n' + paymentRecord + '\n';
    totalAmountFile = totalAmountFile + value.amount;
    if (recordCounter === 50 || recordCounter + fileCount * 50 == records.length) {
      const lbFooter = createLbFooter('0123456789', recordCounter, totalAmountFile);
      files.push(lbHeader + '\n' + recordList + lbFooter);
      recordList = '';
      recordCounter = 0;
      totalAmountFile = 0;
      fileCount = fileCount + 1;
    }
    recordCounter = recordCounter + 1;
  }
  return files;
};

export const parseJwt = (token: string) => {
  const splitted = token.split('.');
  console.log(splitted);
  const tokenjsonstr = atob(splitted[1]);
  return JSON.parse(tokenjsonstr);
};

const randomchar = () => {
  const n = Math.floor(Math.random() * 62);
  if (n < 10) return n; //1-10
  if (n < 36) return String.fromCodePoint(n + 55); //A-Z
  return String.fromCodePoint(n + 61); //a-z
};

export const randomstring = (L: number) => {
  let s = '';
  while (s.length < L) s += randomchar();
  return s;
};

export function deepCompare(arg1: any, arg2: any): any {
  if (Object.prototype.toString.call(arg1) === Object.prototype.toString.call(arg2)) {
    if (
      Object.prototype.toString.call(arg1) === '[object Object]' ||
      Object.prototype.toString.call(arg1) === '[object Array]'
    ) {
      if (Object.keys(arg1).length !== Object.keys(arg2).length) {
        return false;
      }
      return Object.keys(arg1).every(function (key) {
        return deepCompare(arg1[key], arg2[key]);
      });
    }
    return arg1 === arg2;
  }
  return false;
}
